import Hero from "../components/Hero/Hero";
import Navbar from "../components/Navbar/Navbar";
import WorksImg from "../assets/5.jpg";
import Footer from "../components/Footer/Footer";
import WorksList from "../components/Worklist/WorksList";

function Works (){
    return(
        <>
        <Navbar />
        <Hero
        cName="hero-mid"
        heroImg={WorksImg}                    
        title=""
        btnClass="hide"
        />
        <WorksList />
        <Footer />
        </>
    )
}

export default Works;