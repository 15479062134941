import "../Mainhead/MainHeadStyles.css"
import mainhead_img from "./../../assets/about.png"

const MainHead = () => {
    return (
        <div className="mainhead">
            <div className="mainhead-left">
                <h1>Инжиниринговая Компания <br />АСУ ПРОМ ЭКСПЕРТ</h1>
                <p>Компания «АСУ ПРОМ ЭСПЕРТ» предлагает полный комплекс услуг по проектированию, разработке и внедрению автоматизированных систем управления, а также инженерных систем для промышленных объектов, офисных зданий, объектов городской инфраструктуры.</p> 
                <p>На производственной площадке «АСУ ПРОМ ЭКСПЕРТ» осуществляется сборка и монтаж электротехнических шкафов различного назначения, условий применения и конструктивного исполнения для систем автоматизации и энергораспределения.</p> 
                <p>«АСУ ПРОМ ЭСПЕРТ» производит все виды работ, связанных со строительством и запуском промышленных и производственных объектов в эксплуатацию.</p>
            </div>
            <div className="mainhead-right">
                <img src={mainhead_img} alt="" className="mainhead-img"/>
            </div>
        </div>
    );
}

export default MainHead