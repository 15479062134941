import React, { useState } from 'react';
import '../Worklist/WorksListStyles.css';
import WorksData from '../Worklist/WorksData';

const WorksList = () => {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    return (
        <div>
            {WorksData.map((work) => (
                <div className="works-container" key={work.id}>
                    <div className="works-image">
                        <img src={work.image} alt={work.title} className="works-img" />
                    </div>
                    <div className="works-text">
                        <h1>{work.title}</h1>
                        <p>{work.content}</p>
                    </div>
                </div>
            ))}
            <div className="accordion-section">
                <button onClick={toggleAccordion} className="accordion-toggle">
                    {isAccordionOpen ? 'Закрыть' : 'Полный список выполненных работ'}
                </button>
                {isAccordionOpen && (
                    <div className="additional-text">
                        <ul className="additional-text-list">
                            <li>Автоматизация диффузионного отделения (диффузионный аппарат DC-8)</li>
                            <li>Внедрение в АСУТП диффузионного аппарата А1-ПД2-С20 системы защиты приводов</li>
                            <li>Внедрение АСУТП моечного отделения</li>
                            <li>Модернизация АСУТП выпарной станции и конденсатного хозяйства в рамках реконструкции с заменой контроллера Premium на М340</li>
                            <li>Внедрение АСУТП конденсатного хозяйства</li>
                            <li>Замена контроллера дефекосатурации с Momentum на М340 с разработкой ПО ПЛК, без изменения графического уровня АСУТП</li>
                            <li>Автоматизация жомопрессового отделения и системы жомоудаления с установкой двух прессов Babbini 32F (РусАгро)</li>
                            <li>Проектирование АТХ установки центрифуг 2 и 3 продукта (Техпроект)</li>
                            <li>Автоматизация системы жомоудаления</li>
                            <li>Внедрение АСУТП Кристаллизатора</li>
                            <li>Разработка и монтаж системы управления барабанными свеклорезками Putsch</li>
                            <li>Замена контроллера выпарной станции с Premium на М340 без изменения графического уровня АСУТП</li>
                            <li>Шеф-монтаж и ПНР нового ЖСО (Залегощенский сахарный комбинат)</li>
                            <li>Разработка и внедрение АСУТП Дефекосатурации (Бековский сахарный комбинат) </li>
                            <li>Монтаж и подключение шкафов управления Вакуум-аппараты, кристаллизатор, сушка сахара, центрифуги 2, 3 продукта. (Земетчинский сахарный завод).  </li>
                            <li>ПНР АСУТП на пуске завода (Бековский сахарный комбинат)</li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WorksList;
