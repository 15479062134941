import "../Footer/FooterStyles.css"

const Footer = () =>{
    return(
        <div className="footer">
            <p>© 2024 АСУ ПРОМ ЭКСПЕРТ</p>
        </div>
    )
}

export default Footer