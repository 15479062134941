import Hero from "../components/Hero/Hero";
import Navbar from "../components/Navbar/Navbar";
import ContactImg from "../assets/6.jpg"
import Footer from "../components/Footer/Footer";
import Map from "../components//Map/Map";

function Contact (){
    return(
        <>
        <Navbar />
        <Hero
        cName="hero-mid"
        heroImg={ContactImg}                    
        title=""
        btnClass="hide"
        />
        <Map />
        <Footer />
        </>
    );
}

export default Contact;