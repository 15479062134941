import Work1Image from '../../assets/Works/1.JPG';
import Work2Image from '../../assets/Works/2.jpg';
import Work3Image from '../../assets/Works/3.jpg';
import Work4Image from '../../assets/Works/4.jpg';
import Work5Image from '../../assets/Works/5.jpg';
import Work6Image from '../../assets/Works/6.jpg';

const WorksData = [
  {
    id: 1,
    title: "Разработка и внедрение АСУТП Фильтрации Сиропа (Бековский сахарный комбинат)",
    content: "Автоматизация работы дисковых фильтров. Сборка и монтаж щита контроллера и пультов местного управления. Разработка ПО и HMI. Пусконаладочные работы в момент пуска завода.",
    image: Work1Image
  },
  {
    id: 2,
    title: "Разработка и внедрение АСУТП Дефекосатурации (Сахарный комбинат Большевик)",
    content: "Автоматизация работы станции очистки сока. Монтаж кабеля и кабеленесущих систем. Монтаж датчиков и управляющей аппаратуры. Сборка и монтаж щита контроллера. Разработка ПО и HMI. Пусконаладочные работы в момент пуска завода.",
    image: Work2Image
  },
  {
    id: 3,
    title: "Разработка и внедрение АСУТП Диффузионного отделения (DC-12) (Сахарный комбинат Большевик)",
    content: "Автоматизация работы диффузионного аппарата и прессов глубокого отжима. Монтаж кабеля и кабеленесущих систем. Монтаж датчиков и управляющей аппаратуры. Сборка и монтаж щита контроллера. Разработка ПО и HMI. Пусконаладочные работы в момент пуска завода.",
    image: Work3Image
  },
  {
    id: 4,
    title: "ПНР АСУТП на пуске завода (Залегощенский сахарный комбинат)",
    content: "Предпусковая наладка. Наладка в момент запуска завода.",
    image: Work4Image
  },
  {
    id: 5,
    title: "ПНР жомосушильного отделения (Технология и АСУТП) (Хохольский сахарный завод)",
    content: "Наладочные работы АСУТП. Технологическая наладка работы станции.",
    image: Work5Image
  },
  {
    id: 6,
    title: "Проектирование АТХ и ЭС: Диффузия, Дефекосатурация, Выпарная станция (Льговский сахарный комбинат)",
    content: "Разработка комплектов чертежей.",
    image: Work6Image
  }
];

export default WorksData;
