import '../Servicelists/ServiceListStyles.css';
import Service_img1 from '../../assets/Service/1.jpg';
import Service_img2 from '../../assets/Service/2.jpg';
import Service_img3 from '../../assets/Service/3.jpg';
import Service_img4 from '../../assets/Service/4.jpg';
import Service_img5 from '../../assets/Service/5.jpg';
import Service_img6 from '../../assets/Service/6.jpg';
import Service_img7 from '../../assets/Service/7.jpeg';
import Service_img8 from '../../assets/Service/8.jpg';

const ServicesData = [
  {
    id: 1,
    title: "Консалтинговые услуги в области автоматизации производства",
    description: "«АСУ ПРОМ ЭКСПЕРТ» оказывает услуги по разработке концептуальных и нормативных документов в области промышленной автоматизации.",
    content: (
      <div>
        <p>Предлагаемые решения основаны на богатом опыте реализации подобных работ, обобщают все лучшие практики, выработанные в ходе их разработки, и учитывают все современные мировые тенденции. Примерный перечень оказываемых услуг:</p>
        <ul className="service-list">
          <li>Разработка концепций, мастер-планов комплексной автоматизации промышленных предприятий, концепций «цифрового», «интеллектуального» завода.</li>
          <li>Разработка концепции гибридных систем контроля качества продуктов.</li>
          <li>Разработка стандартов по типовым проектным решениям в части реализации АСУТП: стандарты эффективности РСУ и СПАЗ, стандарты по реализации операторских интерфейсов и пр.</li>
          <li>Разработка стандартов информационной безопасности АСУТП.</li>
          <li>Разработка нормативной документации в области промышленной автоматизации при оказании услуг по техническому сервису КИПиА и АСУТП: производственные регламенты, технологические карты.</li>
          <li>Разработка комплекта организационной и нормативной документации при выводе на аутсорсинг услуг по техническому обслуживанию и ремонту КИПиА и АСУТП, а также персонала их выполняющего.</li>
        </ul>
      </div>
    ),
    image: Service_img1
  },
  {
    id: 2,
    title: "Проектирование систем АСУТП",
    description: "Одним из основных направлений деятельности нашей компании является проектирование систем автоматизации.",
    content: (
      <div>
        <p>Проектирование АСУ ТП – процесс, целью которого является повышение эффективности производственных процессов и качества продукции. Автоматизирования система позволяет обеспечить кардинально новое качество администрирования процессов производственного предприятия.</p>
        <p>Внедрение АСУ ТП позволяет снизить долю ручного труда, исключить риск возникновения ошибки из-за человеческого фактора, обеспечить высокую точность производства, уменьшить затраты энергетических и сырьевых ресурсов. При помощи автоматизации технологических процессов предприятия повышают качество выпускаемых изделий, увеличивают выработку, снижают себестоимость продукции и увеличивают рентабельность производства.</p>
      </div>
    ),
    image: Service_img2
  },
  {
    id: 3,
    title: "Сборка шкафов",
    description: "Компания «АСУ ПРОМ ЭКСПЕРТ» может предложить изготовление щитов автоматики на любой аппаратной базе в соответствии с проектом, разработанным по техническому заданию.",
    content: (
      <div>
        <p>Наш обученный высококвалифицированный персонал предложит вам оптимальную схему и комплектацию шкафа управления в зависимости от производственной задачи, требующей решения.</p>
        <p>Сборка и установка шкафа - важный этап для обеспечения эффективного управления и надежной работы системы. Точная сборка требует внимательности и опыта, чтобы каждый компонент был правильно подключен.</p>
        <p>Установка оборудования также играет ключевую роль. Необходимо выбрать оптимальное место, учитывая требования к безопасности и доступности. Кроме того, управление должно быть интуитивно понятным для оператора, чтобы обеспечить удобную работу и быструю реакцию на изменения.</p>
        <p>Профессиональная работа специалистов гарантирует правильную сборку и установку, что влияет на надежность и долговечность системы. Требования к сборке и установке шкафа автоматики направлены на обеспечение безотказной работы и оптимального управления техническими процессами.</p>
        <p>Одним из важных аспектов сборки шкафа автоматики является оптимальное использование пространства внутри шкафа. Эффективная организация компонентов шкафа и проводки помогает упростить обслуживание и отладку оборудования, а также улучшить доступность и надежность.</p>
        <p>Стандартные процедуры монтажа шкафа автоматики включают в себя проверку электрических соединений, испытания и настройку компонентов, а также проверку работоспособности всего оборудования.</p>
      </div>
    ),
    image: Service_img3
  },
  {
    id: 4,
    title: "Разработка программного обеспечения для управления технологическими процессами",
    description: "Компания «АСУ ПРОМ ЭКСПЕРТ» разрабатывает и внедряет системы HMI (человеко-машинного интерфейса), позволяющие обеспечивать контроль и управление за процессами производства на Вашем предприятии.",
    content: (
      <div>
        <p>Прикладная программа системы диспетчеризации – это продукт разработки и внедрения программного обеспечения АСУ ТП, учитывающего индивидуальные особенности и нужды конкретного предприятия для управления технологическими процессами, мониторинга установок и приборов, контроля рабочих параметров, экономичного расходования энергоресурсов и др.</p>
        <p>Применение специализированного ПО позволяет эффективно управлять предприятием для решения задач: производственных, экономических и безопасности. В отличие от типовых программных продуктов предполагает использование требуемых на конкретном производстве функций, что упрощает и удешевляет схему диспетчеризации зданий, сооружений и предприятий.</p>
      </div>
    ),
    image: Service_img4
  },
  {
    id: 5,
    title: "Рациональный подбор оборудования",
    description: "Наши специалисты готовы помочь с выбором оборудования для Вашего предприятия. Подберем оборудование в зависимости от ассортимента, массы сырья, производительности цеха по каждому виду продукции.",
    content: (
      <div>
        <p>Выбор и расчет технологического оборудования является одним из наиболее важных этапов выполнения проекта. Его правильный выбор во многом обусловливает ритмичную работу предприятия, качество выпускаемой продукции, производительность труда, размеры прибыли и рентабельность.</p>
        <p>Выбор оборудования зависит от экономической целесообразности установки данного вида оборудования, его производительности. При прочих равных условиях предпочтительнее выбирать автоматическое или полуавтоматическое оборудование.</p>
      </div>
    ),
    image: Service_img5
  },
  {
    id: 6,
    title: "Монтажные и пусконаладочные работы",
    description: "Компания «АСУ ПРОМ ЭКСПЕРТ» осуществляет монтажные и пусконаладочные работы. Наша организация специализируется на разработке и настройке комплексных систем автоматизации для объектов различного уровня.",
    content: (
      <div>
        <p>Автоматизацию производственных процессов обеспечивает целый комплекс устройств, состав которого зависит от назначения объекта. Система включает в себя компоненты, отвечающие за ввод и отображение информации, модули для получения и преобразования сигнала, компоненты хранения данных. Монтаж и наладка систем автоматизации предполагает работу с такими устройствами, как:</p>
        <ul className="service-list">
          <li>Информирующие приборы;</li>
          <li>Контрольно-измерительные приборы;</li>
          <li>Вычислительные и микропроцессорные устройства;</li>
          <li>Сигнализирующие приборы;</li>
          <li>Линии связи – волоконно-оптические каналы, электропроводка, модемы, повторители;</li>
          <li>Защитные конструкции (столы, стойки, пульты, шкафы).</li>
        </ul>
        <p>Чтобы проводить пусконаладочные работы АСУ, необходимо иметь высокую квалификацию, поскольку от правильной настройки во многом зависит эффективность и стабильность работы системы автоматизации. Любые допущенные ошибки могут привести к сбоям и к нарушению установленных технологических процессов.</p>
      </div>
    ),
    image: Service_img6
  },
  {
    id: 7,
    title: "Производственный аудит предприятия",
    description: "Мы можем предложить выполнить производственный аудит предприятия нашими специалистами. Специалисты проведут процесс систематической проверки и анализа производственных процессов, систем управления, использования ресурсов и качества продукции на предприятиях промышленного сектора.",
    content: (
      <div>
        <p>Целью промышленного аудита является выявление проблем, определение потенциальных улучшений и рекомендации по повышению эффективности, безопасности, экологической устойчивости и конкурентоспособности предприятия. Наши специалисты проводят анализ данных, осуществляют осмотры и наблюдение за производственными процессами, а также проводят интервью с сотрудниками для получения всестороннего понимания работы предприятия.</p>
        <p>Задачи промышленного аудита:</p>
        <ul className="service-list">
          <li>Анализ производственных процессов: одной из основных задач промышленного аудита является анализ производственных процессов предприятия. Специалисты изучают каждый этап производства, начиная от поступления сырья и заканчивая отгрузкой готовой продукции. Они анализируют эффективность и эргономику рабочих мест, используемое оборудование и технологии, а также процессы управления производством.</li>
          <li>Оптимизация ресурсов: промышленный аудит направлен на оптимизацию использования ресурсов предприятия. Специалисты анализируют расходы на энергию, воду, сырье и материалы, ищут возможности снижения издержек и повышения эффективности использования ресурсов. Они также оценивают возможности внедрения энергосберегающих технологий и процессов.</li>
          <li>Качество продукции: промышленный аудит включает анализ качества производимой продукции. Специалисты оценивают соответствие продукции стандартам качества, проводят анализ брака и отходов, а также исследуют причины возникновения дефектов. Они рекомендуют меры по улучшению качества продукции и процессов, чтобы минимизировать брак и повысить удовлетворенность клиентов.</li>
          <li>Безопасность и экологическая устойчивость: промышленный аудит включает оценку безопасности труда и соответствия предприятия экологическим стандартам. Специалисты проводят анализ условий работы, идентифицируют потенциальные опасности и риски, а также рекомендуют меры по обеспечению безопасности и снижению воздействия на окружающую среду.</li>
        </ul>
      </div>
    ),
    image: Service_img7
  },
  {
    id: 8,
    title: "Обслуживание и модернизация систем АСУТП",
    description: "Наша компания осуществляет также модернизацию и обслуживание систем АСУТП. ",
    content: (
      <div>
        <p>Наряду с разработкой автоматизированных систем управления "с нуля" мы часто сталкиваемся с проектами по модернизации АСУ ТП. Задача очень актуальна, так как своевременная модернизация позволяет снизить эксплуатационные затраты и значительно продлить срок службы технологического оборудования. Модернизация бывает различной, как и ее причины. Но основные ситуация, в которых требуется внесение изменений в систему управления, следующие:</p>
        <ul className="service-list">
          <li>Физическое старение оборудования: ПЛК и, чаще всего, операторских панелей (не работает дисплей или сенсорное управление), а также промышленных компьютеров. Частые поломки, нестабильная работа, потребность в постоянном ремонте;</li>
          <li>Моральное старение оборудования и программного обеспечения: сложность поиска, большие сроки и высокая стоимость запасных частей; дорогое обслуживание; отсутствие специалистов для работы с устаревшим оборудованием и ПО;</li>
          <li>Изменения в технологическом процессе или оборудовании: например, увеличение количества оборудования, увеличение производительности, изменения в технологии. Все это требует внесений изменений и в АСУ ТП;</li>
          <li>Отсутствие актуальной документации и программного обеспечения: нет вовсе или только устаревшие варианты схем и отсутствие программы на контроллер и панель оператора сильно усложняет процесс обслуживания АСУ.</li>
        </ul>
        <p>К основным преимуществам внедрения модернизированных средств автоматизации относятся следующие аспекты:</p>
        <ul className="service-list">
          <li>Визуализация (наглядное представление) технологических процессов;</li>
          <li>Сокращение производственных издержек, повышение рентабельности;</li>
          <li>Полный контроль всех участков и технологических зон предприятия;</li>
          <li>Возможность составления подробных графиков и отчетов.</li>
        </ul>
        <p>Регулярное техническое обслуживание помогает предотвращать критические сбои, которые могут привести к простоям в производстве. Это снижает риски потери производительности и уменьшает финансовые потери компании. Различные виды работ, выполняемых при обслуживании АСУТП:</p>
        <ul className="service-list">
          <li>Аппаратная диагностика и обслуживание, что включает в себя проверку состояния шкафов управления, ПЛК, датчиков и приборов, коммутационного оборудования, сетевых устройств и других элементов инфраструктуры.</li>
          <li>Программная поддержка, обновление и расширение функционала АСУТП, внедрение улучшений и исправлений. Работы по программной поддержке включают в себя, в том числе, модернизацию с внедрением современных решений в области автоматизации.</li>
          <li>Оптимизация процессов и настройка параметров, поскольку это позволяет поддерживать соответствие системы актуальным потребностям предприятия.</li>
          <li>Обучение персонала, что включает в себя проведение тренингов, обучение новым функциональным возможностям системы и обновленным процедурам эксплуатации.</li>
        </ul>
      </div>
    ),
    image: Service_img8
  }
];

export default ServicesData;