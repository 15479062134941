import "../Map/MapStyles.css";

const Map = () =>{
    return(
        <div className="Map">
            <div className="contact-col">
                <h3>Контакты и адрес</h3>
                <ul>
                    <li><i class="fa-solid fa-envelope"></i>asupromexpert@mail.ru</li>
                    <li><i class="fa-solid fa-phone"></i>8(8452)58-79-99</li>
                    <li><i class="fa-solid fa-mobile"></i>+7-905-380-89-99</li>
                    <li><i class="fa-solid fa-location-dot"></i>412315, Россия, Саратовская область, г. Балашов, ул. Карла Маркса, д. 48, офис 302</li>
                </ul>
            </div>
            <div className="contact-col">
            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A4590d99e9f3b00427f0f9ead6f9ec27bed21526c2bc1ce9751686bf7fd5694b4&amp;source=constructor" title="map" width="100%" height="100%" frameborder="0"></iframe>
            </div>
        </div>
    )
}

export default Map