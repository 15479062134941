// src/routes/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  container: {
    textAlign: 'center',
    padding: '50px',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '3em',
    marginBottom: '20px',
    color: '#0d4b4d',
  },
  text: {
    fontSize: '1.5em',
    marginBottom: '40px',
  },
  link: {
    fontSize: '1.2em',
    color: '#105b63',
    textDecoration: 'none',
  },
  linkHover: {
    textDecoration: 'underline',
  },
};

function NotFound() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>404 - Страница не найдена</h1>
      <p style={styles.text}>Простите, страница, которую вы ищете, не существует.</p>
      <Link
        to="/"
        style={styles.link}
        onMouseOver={(e) => (e.target.style.textDecoration = styles.linkHover.textDecoration)}
        onMouseOut={(e) => (e.target.style.textDecoration = styles.link.textDecoration)}
      >
        Вернутся на Главную
      </Link>
    </div>
  );
}

export default NotFound;
