import React, { Component } from "react";
import "../Navbar/NavbarStyles.css";
import { MenuItems } from "../Navbar/MenuItems";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";

class Navbar extends Component {
    state = { 
        clicked: false,
        activeIndex: null
    };

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked });
    }

    handleItemClick = (index) => {
        window.scrollTo(0, 0);
        this.setState({ activeIndex: index, clicked: false });
    }

    handleLogoClick = () => {
        window.scrollTo(0, 0);
        this.setState({ activeIndex: 0, clicked: false }); // Активная Главная при нажатии на картинку
    }

    handleClickOutside = (event) => {
        if (this.state.clicked && this.navbar && !this.navbar.contains(event.target)) {
            this.setState({ clicked: false });
        }
    }

    componentDidMount() {
        const currentPath = window.location.pathname;
        const activeIndex = MenuItems.findIndex(item => item.url === currentPath);
        this.setState({ activeIndex });

        document.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    }

    render() {
        return (
            <nav className="NavbarItems" ref={element => this.navbar = element}>
                <Link to="/" onClick={this.handleLogoClick} className="navbar-logo-container">
                    <img src={Logo} alt="Logo" className="navbar-logo" />
                </Link>
                <div className="menu-icons" onClick={this.handleClick}>
                    <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
                </div>
                <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
                    {MenuItems.map((item, index) => (
                        <li key={index}>
                            <Link 
                                className={`nav-links ${this.state.activeIndex === index ? 'active' : ''}`} 
                                to={item.url} 
                                onClick={() => this.handleItemClick(index)}
                            >
                                <i className={item.icon}></i>{item.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        );
    }
}

export default Navbar;
