import Hero from "../components/Hero/Hero";
import Navbar from "../components/Navbar/Navbar";
import HomeImg from "../assets/home.jpg"
import Project from "../components/Projects/Project";
import Footer from "../components/Footer/Footer";
import MainHead from "../components/Mainhead/MainHead";

function Home (){
    return(
        <>
        <Navbar />
        <Hero
        cName="hero"
        heroImg={HomeImg}                    
        title=""
        text=""
        buttonText="Кнопочка"
        url="/"
        btnClass="hide"
        />
        <MainHead />
        <Project />
        <Footer />
        </>
    );
}

export default Home;