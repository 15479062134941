import Hero from "../components/Hero/Hero";
import Navbar from "../components/Navbar/Navbar";
import ServiceImg from "../assets/2.jpg"
import Footer from "../components/Footer/Footer";
import ServiceList from "../components//Servicelists/ServiceList";

function Service (){
    return(
        <>
        <Navbar />
        <Hero
        cName="hero-mid"
        heroImg={ServiceImg}                    
        title=""
        btnClass="hide"
        />
        <ServiceList />
        <Footer />
        </>
    );
}

export default Service;