import "../About/AboutUsStyles.css";

function AboutUs() {
  return (
    <div className="about-container">
      <div className="about-section">
        <h1>О нас</h1>
        <p>Компания «АСУ ПРОМ ЭКСПЕРТ» занимается разработкой и внедрением комплексных решений в сфере автоматизации технологических процессов.</p>
      </div>
      <div className="about-section">
        <h1>Мы предлагаем</h1>  
        <ul>
          <li>Консалтинговые услуги в области автоматизации производства;</li>
          <li>Разработку, проектирование и монтаж систем АСУТП;</li>
          <li>Разработку программного обеспечения для управления технологическими станциями;</li>
          <li>Рациональный подбор оборудования;</li>
          <li>Монтажные и пусконаладочные работы;</li>
          <li>Производственный аудит предприятия;</li>
          <li>Обслуживание и модернизацию систем АСУТП.</li>
        </ul>
      </div>
      <div className="about-section">
        <h1>Наша команда</h1>
        <p>Наша команда состоит из квалифицированных специалистов в области автоматизации промышленности. Наши сотрудники имеют профильное образование и опыт работы на производстве как в качестве разработчиков АСУТП, так и персонала производства. Наши специалисты это: инженеры КИП и АСУТП, проектировщики, технологи, теплотехники.</p>
        <p>Наши специалисты проходят курсы повышения квалификации в Центре обучения ведущих мировых производителей средств автоматизации.</p>
      </div>
    </div>
  );
}

export default AboutUs;
