import React from 'react';
import '../Sugar/SugarprodStyles.css';
import SugarImg from '../../assets/sugar.jpg';

const Sugarprod = () => {
  return (
    <div className="sugar-container">
      <div className="sugar-image">
        <img src={SugarImg} alt="Product" className="sugar-img" />
      </div>
      <div className="sugar-text">
        <h1>Сахарное производство</h1>
        <p>Для предприятий сахарной промышленности мы предлагаем комплексные решения на любом этапе производства. Готовы выполнить технологический аудит, разработку проектной документации, внедрение схем и решений.</p>
        <p>Сотрудники компании «АСУ ПРОМ ЭКСПЕРТ» могут выполнить работы по запуску отдельных станций и всего завода. Наши специалисты имеют соответствующее образование и многолетний опыт работы в сахарной промышленности. Основные составные части проведения пусконаладочных работ:</p>
        <ul>
          <li>Определение недостатков</li>
          <li>Выход на оптимальный режим работы</li>
          <li>Составление технологических карт</li>
          <li>Инструктаж и обучение рабочего персонала</li>
        </ul>
      </div>
      <div className="sugar-text">
        <h1>Наши сильные стороны</h1>
        <ul>
          <li>Мы имеем большой опыт в автоматизации сахарного производства на любой стадии;</li>
          <li>Осуществляем не просто автоматизацию сахарного производства, но и производим комплексную модернизацию предприятий с выполнением функции генерального подрядчика;</li>
          <li>Имеем опыт работы со всеми АСУТП и их разработчиками, представленными на рынке автоматизации промышленности России. Знаем все их плюсы и минусы, готовы дать бесплатную консультацию по вопросам их модернизации;</li>
          <li>Знаем на личном опыте лучшее и зарекомендовавшее себя на сахарных заводах оборудование для выполнения любых задач по автоматизации;</li>
          <li>Понимая гибкость и разнообразие технологических схем, осуществляем расширение и изменение существующих АСУТП в любом объеме: от добавления контролируемых параметров до изменения алгоритмов регулирования и внедрения любых новых регуляторов;</li>
          <li>Знаем специфику внедренных на сахарных заводах АСУТП, предлагаем замену устаревших линеек ПЛК, без изменения графического уровня системы привычного для вашего персонала;</li>
          <li>Мы, Российская компания и способны обеспечить вам техническую поддержку по любым вопросам в режиме 24/7 с организацией оперативного выезда на ваше предприятие.</li>
        </ul>
      </div>
      <div className="sugar-text">
        <h3>Также, выходя за рамки АСУТП, наши специалисты готовы выполнить:</h3>
        <ul>
          <li>Аудит производства и определение целей реконструкции</li>
          <li>Проектирование и выбор концепции развития</li>
          <li>Разработку бюджета проекта</li>
          <li>Ведение реконструкции в любых объемах от консультации до выполнения функции генерального подрядчика</li>
          <li>Аутсорсинг и предоставление специалистов</li>
          <li>ПНР любой сложности</li>
        </ul>
      </div>
      <div className="sugar-text">
        <p>Приглашаем вас к сотрудничеству! Будем рады приглашению на ваше предприятие для обсуждения любых ваших вопросов, предложим и подскажем рациональные пути их решения.</p>
      </div>
    </div>
  );
}

export default Sugarprod;
