import Project1 from "../../assets/Advantages/1.jpg"
import Project2 from "../../assets/Advantages/2.jpg"
import Project3 from "../../assets/Advantages/3.jpg"
import Project4 from "../../assets/Advantages/4.jpg"
import ProjectData from "../Projects/ProjectData"
import "../Projects/ProjectData"

const Project = () =>{
    return(
        <div className="project">
            <h1>Преимущества работы с нами</h1>
            <ProjectData
                className="first-project"
                heading=""
                text={
                    <ul>
                        <li>Большой опыт в сфере автоматизации производства;</li>
                        <li>Профессионализм сотрудников, прошедших полную аттестацию в своих направлениях;</li>
                        <li>Выполнение всех договорных обязательств перед заказчиками, включающих в себя гарантийные и постгарантийные обязательства;</li>
                    </ul>
                }
                img1={Project1}
                img2={Project2}
            />
            <ProjectData
                className="first-project-reverse"
                heading=""
                text={
                    <ul>
                        <li>Внедрение новых технологий и методов;</li>
                        <li>Полное погружение в предметную область вашего предприятия;</li>
                        <li>Соблюдение всех норм права и деловой этики;</li>
                        <li>Направленность на долгосрочное сотрудничество;</li>
                    </ul>
                }
                img1={Project3}
                img2={Project4}
            />
        </div>
    );
}

export default Project
