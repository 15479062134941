import React, { useState } from 'react';
import '../Servicelists/ServiceListStyles.css';
import ServiceData from '../Servicelists/ServiceData';

const ServiceList = () => {
    const [accordionOpen, setAccordionOpen] = useState(Array(ServiceData.length).fill(false));

    const toggleAccordion = (index, event) => {
        event.preventDefault();
        setAccordionOpen(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };

    const handleAccordionContentClick = (index, event) => {
        toggleAccordion(index, event);
    };

    return (
        <div>
            {ServiceData.map((service, index) => (
                <div className="servicelist" key={service.id}>
                    <div className="servicelist-image">
                        <img src={service.image} alt="" className="servicelist-img" />
                    </div>
                    <div className="servicelist-text">
                        <h1 onClick={(e) => toggleAccordion(index, e)} style={{ position: 'relative' }}>
                            {service.title}
                        </h1>
                        <p onClick={(e) => toggleAccordion(index, e)} className="accordion-text">
                            {service.description}
                        </p>
                        <div className={`accordion-content ${accordionOpen[index] ? 'open' : ''}`} onClick={(e) => handleAccordionContentClick(index, e)}>
                            <p>
                                {service.content}
                            </p>
                        </div>
                        <i onClick={(e) => toggleAccordion(index, e)} className={`fas ${accordionOpen[index] ? 'fa-chevron-up' : 'fa-chevron-down'} accordion-icon`} style={{ fontSize: '20px', transition: 'transform 0.2s ease-in-out' }}></i>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ServiceList;
