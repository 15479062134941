import Hero from "../components/Hero/Hero";
import Navbar from "../components/Navbar/Navbar";
import SugarImg from "../assets/4.jpg"
import Footer from "../components/Footer/Footer";
import Sugarprod from "../components/Sugar/Sugarprod";

function Sugar (){
    return(
        <>
        <Navbar />
        <Hero
        cName="hero-mid"
        heroImg={SugarImg}                    
        title=""
        btnClass="hide"
        />
        <Sugarprod />
        <Footer />
        </>
    );
}

export default Sugar;