export const MenuItems = [
    {
        title : "Главная",
        url : "/",
        cName : "nav-links",
        icon : "fa-solid fa-house"
    },
    {
        title : "Услуги",
        url : "/service",
        cName : "nav-links",
        icon : "fa-solid fa-briefcase"
    },
    {
        title : "О нас",
        url : "/about",
        cName : "nav-links",
        icon : "fa-solid fa-circle-info"
    },
    {
        title : "Сахарное производство",
        url : "/sugar",
        cName : "nav-links",
        icon : "fa-solid fa-flask"
    },
    {
        title : "Наши работы",
        url : "/works",
        cName : "nav-links",
        icon : "fa-solid fa-gears"
    },
    {
        title : "Контакты",
        url : "/contact",
        cName : "nav-links",
        icon : "fa-solid fa-address-book"
    },
]